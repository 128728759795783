import {mainState as ms, noScroll} from "../initData"
import CalcPlug from "./calc/CalcPlug"
import Callback from "./callback/Callback"
import ImgWrapper from "./imgWrapper/ImgWrapper"

const FastBlock = () => {
   noScroll()
   
   switch (ms.location.state?.fast) {
      case 'созвонимся?':
         return <Callback />

      case 'калькулятор':
         return <CalcPlug />

      case 'img':
         return <ImgWrapper />

      default:
         return <></>
   }
}

export default FastBlock