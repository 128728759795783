import {mainState as ms} from '../../initData'

export const getNumMileage = str => {
   let mileage = ''

   for (let i in str) {
      if (+str[i] || +str[i] === 0) mileage += str[i]
   }

   return +mileage
}

// возвращает {} state для FilterBlock по статусу лота (Исправен или Повреждён)
export function createFilterBlockState(status) {
   if (!ms.cache.lotsFetch) return ms.filter
   
   const lots = ms.cache.lotsFetch.filter(e => e.status_lot === status)
   const filter = {controls: {}}
   
   // устанавливает флаг для вкл/откл FilterBlock
   filter.disabled =
      ms.url.models[0] && (ms.url.front === 'online' || ms.url.find) ?
         true : false

   filter.ranked = status === 'Ranked' ? true : false
   filter.recycle = !filter.ranked

   const ratings = [...new Set(lots.map(e => e.rate) )]
   const years = [...new Set(lots.map(e => e.year) )]
   const mileages = [...new Set(lots.map(e => getNumMileage(e.mileage) ) )]
   
   filter.rateMax =
      filter.recycle ? '' : +ms.url.filter.rateMax || Math.max(...ratings)

   filter.rateMin =
      filter.recycle ? '' : +ms.url.filter.rateMin || Math.min(...ratings)

   filter.controls.rateMax = Math.max(...ratings)
   filter.controls.rateMin = Math.min(...ratings)

   const opt = e => <option key={e}>{e}</option>

   filter.ratingsMax = filter.recycle ? '' : 
      [...ratings].sort((a, b) => +b - +a).map(opt)

   filter.ratingsMin = filter.recycle ? '' :
      ratings.sort((a, b) => +a - +b).map(opt)

   filter.yearMax = +ms.url.filter.yearMax || Math.max(...years)
   filter.yearMin = +ms.url.filter.yearMin || Math.min(...years)
   filter.controls.yearMax = Math.max(...years)
   filter.controls.yearMin = Math.min(...years)
   filter.yearsMin = [...years].sort((a, b) => +a - +b).map(opt)
   filter.yearsMax = years.sort((a, b) => +b - +a).map(opt)

   filter.mileageMin = +ms.url.filter.mileageMin || Math.min(...mileages)
   filter.mileageMax = +ms.url.filter.mileageMax || Math.max(...mileages)
   filter.controls.mileageMax = Math.max(...mileages)
   filter.controls.mileageMin = Math.min(...mileages)
   filter.mileagesMin = [...mileages].sort((a, b) => +a - +b).map(opt)
   filter.mileagesMax = mileages.sort((a, b) => +b - +a).map(opt)

   return filter
}
