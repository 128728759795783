import {mainState} from "../initData"

const getAucDays = longMonth => {
   const today = new Date()
   const day = today.getDay()

   const dates = []

   dates[0] = day === 3 || day === 5 ? 'сегодня' :
      mainState.localDate({date:
         new Date(today.getFullYear(), today.getMonth(), today.getDate() + (
            day === 0 ? 3 :
            day === 1 ? 2 :
            day === 2 ? 1 :
            day === 4 ? 1 : 4       
         )
      ), ru: 1, longMonth}
   )

   dates[1] = mainState.localDate({date:
      new Date(
         today.getFullYear(), today.getMonth(), today.getDate() + (
            day === 0 ? 5 :
            day === 1 ? 4 :
            day === 2 ? 3 :
            day === 3 ? 2 :
            day === 4 ? 6 :
            day === 5 ? 5 : 6    
         )
      ), ru: 1, longMonth}
   )
   
   return dates
}

export {getAucDays}