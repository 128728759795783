import stl from './style.module.css'

function Footer() {
   return (
      <footer className={stl.footer}>
         <p>информация на сайте не является публичной офертой</p>
      </footer>
   )
}

export default Footer