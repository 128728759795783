import {mainState as ms} from '../../initData'
import {useState} from 'react'
import stl from '../style.module.css'

function NavFilter () {
   const {location: loc, navigate} = ms
   const [state, setState] = useState({})
   const [navMob, setNavMob] = useState(false)
   ms.nav = {navMob, setNavMob, state, setState}

   const navActive = []
   const lotCard = loc.pathname.includes('lot')

   const navName = {
      'созвонимся?': loc.pathname,
      торги: '/online',
      статистика: '/stat',
      калькулятор: loc.pathname,
   }

   const handleClick = e => {
      let target = e.target.localName === 'img' ?
         e.target.nextSibling.textContent : e.target.textContent

      if (ms.url.front === 'online' && target === 'торги') return
      if (ms.url.front === 'stat' && target === 'статистика') return

      const fastIsVisible =
         (loc.state?.fast && true) || ms.mobile()

      ms.saveCurrentFullUrl()

      if (lotCard && target.includes('статистика') ) {
         navigate(
            '/stat/'
            + ms.lotCard.lot.marka_name + '/'
            + ms.lotCard.lot.eng_v + '/'
            + ms.lotCard.lot.model_name, {
            replace: fastIsVisible
         })
      } else if (ms.url.front === 'online' && target === 'статистика') {
         navigate(navName[target], {
            replace: fastIsVisible,
            state: {fast: target}
         })
      } else if (ms.url.front === 'stat' && target === 'торги') {
         navigate(navName[target], {
            replace: fastIsVisible,
            state: {fast: target}
         })
      } else {
         target = target === 'как заказать?' ? 'созвонимся?' : target

         navigate(navName[target] + loc.search, {
            replace: fastIsVisible,
            state: {fast: target, upClose: 1}
         })
      }
   }

   const getImg = str => str === 'созвонимся?' && lotCard ?
         require(`../img/корзина.webp`)
      : (
         str.includes('?') ?
            require(`../img/созвонимся.webp`) :
            require(`../img/${str}.webp`)
      )

   const getCaption = str =>
      str === 'статистика' && lotCard ?
         (
            <div className={stl.statName}>
               статистика <br />
               {ms.lotCard.lot?.model_name}
            </div>
         )
      :
         str === 'созвонимся?' && lotCard ?
            <div>как заказать?</div> : <div>{str}</div>

   for (const prop in navName) {
      let fast = loc.state?.fast === 'как заказать?' ?
         'заявка' : loc.state?.fast
      
      navActive.push(
         <div
            className={
               fast === prop ? stl.iconActiveFilter :
               loc.pathname.includes('online') &&
               !fast && prop === 'торги' ? stl.iconActiveFilter :
               loc.pathname.includes('stat') &&
               !fast && prop === 'статистика' ? stl.iconActiveFilter
                  : stl.iconFilter
            }
            key={prop}
            onClick={handleClick}
         >
            <img
               alt="icon"
               src={getImg(prop)}
            />
            {getCaption(prop)}
         </div>
      )
   }

   return (
      <nav className={navMob ? stl.navMob : stl.nav}>
         {navActive}
      </nav>
   )
}

export default NavFilter