import {mainState as ms} from '../../initData'
import {emptyFetch} from './emptyFetch'
import {serverError} from './serverError'

export function fetchFindLot() {
   setTimeout(() => ms.preloader(1) )
   
   ms.cache.lastFind = ms.url.find
   
   fetch('/server/findLotNum', {
      method: 'post',
      headers: {
         'content-type': 'application/json'
      },
      body: JSON.stringify(ms.url)
   })
   .then(res => {
      if (!res.ok) {
         serverError()

         throw new Error(res.statusText)
      }

      return res.json()
   })
   .then(res => {
      if (res.length) {
         ms.cache.lotsFetch = res

         ms.navigate(ms.location.pathname + ms.location.search, {replace: true})
      } else emptyFetch()
      
      ms.preloader(0)
   })
   .catch(e => serverError(e) )
}
