export const patternRateText = {
   'Frame': {
      'Mainframe': '',
      'Downtube': '',
      'Stopper': '',
      'Seatrail': '',
      'Step': '',
      'Stand': '',
   },
   'Engine': {
      'Engine': '',
      'Crankcase cover': '',
      'Oil leak': '',
      'Radiator': '',
      'Carburetor': '',
      'Clutch': '', 	
      'Starting motor': '',
   },
   'Electronic Safety Parts': {
      'Key': '',
      'Meter': '',
      'Turn-signal': '',
      'Headlamp': '',
      'Battery': '',
      'Horn': '',
      'Back-lamp': '',
      'Mirror': '',
      'Silencer': '',
      'Exhaustpipe': '',
   },
   'Front': {
      'Tube outer': '',
      'Tube inner': '',
      'Stem steering': '', 	
      'Steering handle bar': '',
      'Front Wheel': '', 	
      'Front Brake': '', 	
      'Front Tire': '',
   },
   'Rear': {
      'Rear shock': '',
      'Swing arm': '',
      'Chain': '',
      'Sprocket': '',
      'Rear Wheel': '',
      'Rear Brake': '',
      'Rear Tire': '',
   },
   'Exterior': {
      'Upper cowl': '',
      'Center cowl': '',
      'Under cowl': '',
      'Side cowl': '',
      'Tank fuel': '',
      'Seat': '',
      'Tail cowl': '',	
      'Front fender': '',
      'Rear fender': '',
      'Screen': '',
   },
}