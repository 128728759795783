import {mainState as ms} from './initData'
import {useRef} from 'react'
import stl from './header/style.module.css'

export function Preloader() {
   const changeClass = useRef()
   ms.preloader = preloader
   
   function preloader(set) {
      set ?
         changeClass.current.className = stl.logoOn :
         changeClass.current.className = stl.logo
   }

   return <div className={stl.logo} ref={changeClass} />
}
