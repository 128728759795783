import {mainState as ms} from '../initData'
import FindLotNum from './findLotNum/FindLotNum'
import SelectBlock from './selectBlock/SelectBlock'
import FilterBlock from './filterBlock/FilterBlock'
import InfoBlock from './infoBlock/InfoBlock'
import ResultBlock from './resultBlock/ResultBlock'
import NavFilter from '../nav/navFilter/NavFilter'
import {getAucDays} from './getAucDays'
import {fetchFindLot} from './fetchs/fetchFindLot'
import {fetchLotsNear} from './fetchs/fetchLotsNear'
import {fetchCountLots} from './fetchs/fetchCountLots'
import {fetchModelsStat} from './fetchs/fetchModelsStat'
import {fetchLotsStat} from './fetchs/fetchLotsStat'
import {createFilterBlockState} from './filterBlock/createFilterBlockState'
import {sortByFilterBlockState} from './filterBlock/sortByFilterBlockState'
import {useEffect, useRef} from 'react'
import stl from './auction.module.css'
import {cleaner} from './cleaner'

const Heading = ({front}) => {
   const countLots = useRef()
   let res
   
   if (front === 'online') {
      res = <>
         <div className={stl.title}>
            <p>торги</p> <FindLotNum />
         </div>
         <div className={stl.title2}>
            ближайшие&ensp;<b>{getAucDays()[0]}</b>,&ensp;
            следующие&ensp;<b>{getAucDays()[1]}</b>
         </div>
      </>
   }

   if (front === 'stat') {
      fetchCountLots()
      .then(res => countLots.current.innerHTML =
         `количество записей в базе данных:&ensp;<b>${res}</b>`
         )
         .catch(() => countLots.current.innerText =
         `что-то пошло не так... попробуйте обновить`
         )

         res = <>
            <div className={stl.title}>
               <p>статистика</p> <FindLotNum /></div>
            <div className={stl.title2} ref={countLots}>&nbsp;</div>
         </>
   }
   
   return res
}

function Auction() {
   const {location: loc} = ms
   const url = ms.url
   const statusLots = ms.cache.statusLots = url.filter.status || 'Ranked'

   cleaner()   
   
   if (url.find) {
      if (url.find !== ms.cache.lastFind) {
         fetchFindLot()
      } else if (url.find === ms.cache.lastFind) {
         ms.cache.filter = createFilterBlockState(statusLots)
         sortByFilterBlockState(ms.cache.filter)         
      }
   }
   else if (url.marka && url.engine) {
      if (url.front === 'online') {
         if (
            url.marka !== ms.cache.lastMarka ||
            url.engine !== ms.cache.lastEngine
         ) {
            fetchLotsNear()
         } else if (loc.pathname !== loc.state?.from) {
            ms.cache.filter = createFilterBlockState(statusLots)
            sortByFilterBlockState(ms.cache.filter)
         }
      }
      
      if (url.front === 'stat') {
         if (url.models[0] && url.models[0] !== ms.cache.lastModel) {
            if (
               url.marka === ms.cache.lastMarka &&
               url.engine === ms.cache.lastEngine
            ) {
               fetchLotsStat()
            } else {
               fetchModelsStat()
               fetchLotsStat()
            }
         } else if (
            url.marka !== ms.cache.lastMarka ||
            url.engine !== ms.cache.lastEngine
         ) {
            fetchModelsStat()
         } else {
            ms.cache.filter = createFilterBlockState(statusLots)
            sortByFilterBlockState(ms.cache.filter)            
         }
      }
   }

   useEffect(() => {
      ms.header.setMenuMob(loc.state?.navMob || loc.state?.upClose)
      ms.nav.setNavMob(loc.state?.navMob)
   })

   return (
      <>
         <Heading front={url.front} />         
         <SelectBlock />
         <FilterBlock />
         <InfoBlock />
         <ResultBlock />
         <NavFilter />
      </>
   )
}

export default Auction
