import {mainState as ms} from '../../initData'
import {emptyFetch} from './emptyFetch'

export function fetchLotCard(id) {
   setTimeout(() => ms.preloader(1) )
   ms.cache.lastLot = id

   fetch('/server/id', {
      method: 'post',
      headers: {
         'content-type': 'application/json'
      },
      body: JSON.stringify({id})
   })
   .then(res => {
      if (!res.ok) {
         ms.preloader(0)

         return new Error(res)
      }

      return res
   })
   .then(res => res.json() )
   .then(res => {
      if (res.length) {
         ms.cache.lotFetch = res

         ms.navigate(ms.location.pathname + ms.location.search, {replace: true})
      } else emptyFetch()

      ms.nav.setState({})
      ms.preloader(0)      
   })
   .catch(err => {
      ms.preloader(0)

      console.dir(err)
   })
}
