import Auction from './auction/Auction'
import Home from './home/Home'
import Layout from './layout/Layout'
import LotPage from './auction/lotPage/LotPage'
import {mainState as ms} from './initData'
import {parseUrl} from './auction/parseUrl'
import {Routes, Route, useLocation, useNavigate} from 'react-router-dom'

const App = () => {
   ms.location = useLocation()
   ms.navigate = useNavigate()
   ms.url = parseUrl()

   const track = ms.previousPageUrl.includes('?')
      ? ms.previousPageUrl.slice(0, ms.previousPageUrl.indexOf('?') )
      : ms.previousPageUrl

   decodeURI(ms.location.pathname) !== track && window.scrollTo(0, 0)

   return (
      <Routes>
         <Route path='/' element={<Layout />}>
            <Route index element={<Home />} />
            <Route path='menuMob' element={<Home />} />
            <Route path=':to' element={<Home />} />
            <Route path='online/*' element={<Auction />} />
            <Route path='stat/*' element={<Auction />} />
            <Route path='lot:id' element={<LotPage />} />
         </Route>
      </Routes>
   )
}

export default App