import {mainState as ms} from '../../initData'
import {getNumMileage} from './createFilterBlockState'

// принимает аргументом {}, state FilterBlock
// создаёт список лотов и моделей по принятому state из FilterBlock
// записывает результат в cache:
// ms.cache.lotsFilter, ms.cache.modelsFilter
export function sortByFilterBlockState(filter) {
   if (!ms.cache.lotsFetch) return

   const lots = ms.cache.lotsFetch // все лоты из последнего FETCH-запроса
   const status = filter.ranked ? 'Ranked' : 'Recycle'

   const res = {
      lots: [],
      models: [],
   }

   res.lots = lots.filter(e => {
      if (
         e.status_lot === status &&
         e.rate >= filter.rateMin && e.rate <= filter.rateMax &&
         e.year >= filter.yearMin && e.year <= filter.yearMax &&
         getNumMileage(e.mileage) >= filter.mileageMin &&
         getNumMileage(e.mileage) <= filter.mileageMax
      ) {
         res.models.push(e.model_name)

         return true
      }
   
      return false
   })

   res.lots.sort((a, b) => ms.url.front === 'online' ?
      (a.lot_num < b.lot_num ? -1 : 1) :
      (a.lot_num < b.lot_num ? 1 : -1)
   )
   .sort((a, b) => ms.url.front === 'online' ?
      (a.auction_date < b.auction_date ? -1 : 1) :
      (a.auction_date < b.auction_date ? 1 : -1)
   )

   ms.cache.lotsFilter = res.lots
   ms.cache.modelsFilter = [...new Set(res.models)].sort()
}
