import {mainState as ms} from '../../initData'
import {serverError} from './serverError'
import {emptyFetch} from './emptyFetch'

export function fetchLotsNear() {
   setTimeout(() => ms.preloader(1) )

   ms.cache.lastEngine = ms.url.engine
   ms.cache.lastMarka = ms.url.marka

   fetch('/server/getLotsNear', {
      method: 'post',
      headers: {
         'content-type': 'application/json'
      },
      body: JSON.stringify(ms.url)
   })
   .then(res => {
      if (!res.ok) {
         serverError()

         throw new Error(res.statusText)
      }

      return res.json()
   })
   .then(res => {
      if (res.length) {         
         ms.cache.lotsFetch = res

         ms.navigate(ms.location.pathname + ms.location.search, {replace: true})
      } else emptyFetch()

      ms.preloader(0)
   })
   .catch(e => serverError(e) )
}
