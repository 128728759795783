const getRightVal = obj => {
   const {totalHeight, pos} = obj
   const ratioRight = totalHeight / 57
   
   return `-${
      (totalHeight - ( totalHeight - pos() )) / ratioRight
   }vw`
}

const homeAnimation = obj => {
   const {angleLines, imgs, sectionsStartStop, middle, ms} = obj
   
   sectionsStartStop.forEach((e, i) => {    
      angleLines.style.right = getRightVal(obj)

      if (middle() > e[0] && middle() < e[1]) {
         ms.nav.setState(i)

         setTimeout(() => imgs[i].style.filter = `
            contrast(1.3) drop-shadow(.5vw .5vw #0000001a)
            drop-shadow(-.5vw -.5vw #0000001a) grayscale(0)`, 1500)
      } else {
         imgs[i].style.filter = `
            contrast(1.3) drop-shadow(.5vw .5vw #0000001a)
            drop-shadow(-.5vw -.5vw #0000001a) grayscale(1)`
      }
   })
}

export {homeAnimation}