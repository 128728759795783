import {mainState as ms} from '../../initData'
import CheckMobile from '../../CheckMobile'
import {costBlock} from '../costBlock'
import stl from './style.module.css'

export function createResultList() {
   const handleMouseEnter = e => {
      e.target.offsetParent.children[2]
      .classList.add(stl.itemMarkaPlateHover)
   }

   const handleMouseLeave = e => {
      e.target.offsetParent.children[2]
      .classList.remove(stl.itemMarkaPlateHover)
   }

   if (!ms.cache.lotsFilter) return ''

   let arr = ms.cache.lotsFilter.filter(e =>
      ms.url.models[0] ? ms.url.models.includes(e.model_name) : true
   )
   
   const res = arr.map(elem => {      
      return (
         <div
            className={stl.resultItemPlate}
            id={elem.id}
            key={elem.id}
         >
            <div className={stl.itemImgPlate}>
               <img
                  alt='img'
                  onError={e => {
                     e.target.parentElement.className+=` ${stl.imgNo}`
                     e.target.offsetParent.children[5]
                        .className+=` ${stl.itemRatingPlateImgNo}`
                     e.target.offsetParent.children[6]
                        .className+=` ${stl.plugOnClickPlateImgNo}`
                     e.target.style.transform = 'scale(0)'
                  }}
                  onLoad={e => {
                     e.target.className=stl.imgOk
                     e.target.style.opacity = 1
                  }}
                  src={elem.image0 ?
                     elem.image0.replace('http://', 'https://')
                     :
                     ms.changeImgUrl(elem.id)}
               />
            </div>
            <div className={stl.itemLotPlate}>
               <CheckMobile lotPlate={{caption: 'lotNum', elem}} />
               <p>
                  {
                     elem.status_lot === 'Ranked' ?
                        <span style={{color: 'green'}}>
                           исправен
                        </span> : 
                        <span style={{color: 'red'}}>
                           повреждён
                        </span>
                  }
               </p>
               {
                  ms.localDate({}) ===
                  ms.localDate({date: elem.auction_date}) ?
                     <p>торги&nbsp;
                        <span style={{fontWeight: 'bold'}}>сегодня</span>
                     </p>
                     :
                     <p>
                        {ms.localDate({date: elem.auction_date, ru: 1})}
                     </p>                      
               }               
            </div>
            <div className={stl.itemMarkaPlate}>
               <p className={elem.marka_name === 'other' ? stl.markaOther : ''}>
                  {elem.marka_name === 'harley-davidson' ? 'HD' :
                     elem.marka_name.toLowerCase()}</p>
               <p>{elem.model_name.toUpperCase()}</p>
               <p>{elem.serial.toUpperCase()}</p>
            </div>
            <div className={stl.itemYearPlate}>
               <p>{elem.year} год</p>
               <p>{elem.eng_v} см³</p>
               <p>{ms.checkMileage(elem.mileage)}
               </p>
            </div>
            <div className={stl.itemCostPlate}>
               {costBlock(elem)}
            </div>
            <div className={stl.itemRatingPlate}>
               {
                  elem.inspection === 'completed' ||
                  elem.inspection === null ?
                     <>
                        <p>оценка</p>
                        <p className={stl.ratingPlate}>{elem.rate}</p>
                     </> :
                     <>
                        <p>ожидается</p>
                        <p>осмотр</p>
                     </>
               }
            </div>
            <div
               className={stl.plugOnClickPlate}
               onMouseEnter={handleMouseEnter}
               onMouseLeave={handleMouseLeave}
            ></div>
         </div>
      )
   })

   return res
}
