import {mainState as ms} from './initData'
import {useState, useEffect} from 'react'

function CheckMobile(props) {
   const [state, setState] = useState(ms.mobile() )
   ms.checkMobile = {state, setState}

   const checkPortrait = e => {
      e.target.innerWidth / e.target.innerHeight < 1.2 ?
         setState(true) : setState(false)
      
      ms.header.setMenuMob(false)
      ms.nav.setNavMob(false)

      ms.saveCurrentFullUrl()
      ms.navigate(ms.location.pathname + ms.location.search, {
         replace: true,
         state: ms.location.state
      })
   }

   useEffect(() => {
      window.addEventListener('resize', checkPortrait)

      return () => {
         window.removeEventListener('resize', checkPortrait)
      }
   })

   const req = Object.keys(props)[0]
   const {stl} = props[req]

   switch (req) {
      case 'home':
         const home = {
            h2: state ?
               'комплексно, от поиска на торгах до передачи заказчику'
               :
               'комплексно выполняем всю работу, от поиска на торгах до передачи заказчику',
            section1: state ?
               <p>
                  С детальным осмотром, оценками и гарантией пробега<br/><br/>
                  <b>&bull; много качественных фотографии&nbsp; всех деталей и узлов<br/>
                  &bull; с точными комментариями и объективными оценками<br/>
                  &bull; есть контроль дымности и шумности мотора, электрики<br/>
                  &bull; добавлены видео с запуском&nbsp; двигателя с обеих сторон</b><br />
               </p>
               :
               <>
                  <span>&ensp;</span>
                  <span>Вся техника проходит детальный осмотр экспертами</span>
                  <span>Проверяют и подтверждают корректность пробега</span>
                  <span>После осмотра составляют точный аукционный лист</span>
                  <span>&ensp;</span>
                  <div className={stl?.list}>
                     <span><b>&#10003;&emsp;много качественных фотографии&nbsp; для всех деталей&nbsp; и узлов</b></span>
                     <span><b>&#10003;&emsp;дополненные комментариями&nbsp; и объективными оценками</b></span>
                     <span><b>&#10003;&emsp;проверка дымности и шумности мотора, а так же электрики</b></span>
                     <span><b>&#10003;&emsp;плюс &nbsp;видео &nbsp;запуска двигателя &nbsp;мотоцикла&nbsp; с обеих сторон</b></span>
                  </div>                  
                  <span>&ensp;</span>
               </>,

            section2: state ?
               <p>
                  В этом разделе собираются итоги всех прошедших торгов<br />
                  остаются доступными&nbsp; данные о цене, оценках, пробегах<br /><br />
                  <b>&bull; меняйте фильтры для быстрого поиска нужных лотов<br />
                  &bull; легко находите и сравнивайте&nbsp; цены по году выпуска<br />
                  &bull; учитывая техсостояние, выбирая все нужные оценки</b><br />
               </p>
               :
               <>
                  <span>&ensp;</span>
                  <span>В этом разделе собираются итоги всех прошедших торгов</span>
                  <span>В результате&nbsp; остаётся доступна &nbsp;вся нужная информация:</span>
                  <span>&ensp;</span>
                  <div className={stl?.list}>
                     <span><b>&bull;&ensp;пробеги, оценки состояния, комментарии экспертов</b></span>
                     <span><b>&bull;&ensp;итоговые цены &nbsp;и&nbsp; результаты торгов&nbsp; для всех лотов</b></span>
                     <span><b>&bull;&ensp;частота появления нужного мотоцикла на аукционах</b></span>
                  </div>
                  <span>&ensp;</span>
                  <span>Меняйте фильтры, чтобы понять цену на нужную модель, учитывая</span>
                  <span>год выпуска техники, пробег и техсостояние (оценки экспертов)</span>
                  <span>&ensp;</span>
               </>,         
            section3: state ?
               <>
                  <h2>Калькулятор точной цены мото с аукциона</h2>
                  <p>
                     Итоговая сумма аукционного мотоцикла состоит из:<br />
                     <b>&bull; цена лота непосредственно на аукционе Японии<br />
                     &bull; логистика и брокерские % за мотоцикл в Японии<br />
                     &bull; фрахт - контракт (морская перевозка мотоцикла)<br />
                     &bull; гос. пошлина,&nbsp; СБКТС,&nbsp;СВХ,&nbsp; таможенный брокер<br />
                     &bull; адресная доставка из Владивостока до заказчика<br />
                     &bull; скромная&nbsp; комиссия&nbsp; MOTOSHAR.ru&nbsp; за&nbsp; помощь</b><br /><br />
                     Всё учтено в калькуляторе, прозрачно и понятно!
                  </p>
               </>
               :
               <>
                  <h2>разработали точный инструмент для расчёта</h2>
                  <h2>калькулятор&nbsp; полной &nbsp;стоимости мотоцикла</h2>
                  <span>&ensp;</span>
                  <span>Итоговая стоимость любого аукционного мотоцикла содержит в себе:</span>
                  <div className={stl?.list}>
                     <span><b>&bull;&ensp;цена мотоцикла &nbsp;непосредственно на аукционе в Японии</b></span>
                     <span><b>&bull;&ensp;транспортировка, хранение, комиссия брокера в Японии</b></span>
                     <span><b>&bull;&ensp;фрахт контракт - морская перевозка&nbsp; мотоцикла в Россию</b></span>
                     <span><b>&bull;&ensp;пошлина, электронный&nbsp; ПТС, СБКТС, СВХ,&nbsp; услуги брокера</b></span>
                     <span><b>&bull;&ensp;адресная доставка мотоцикла из Владивостока до клиента</b></span>
                     <span><b>&bull;&ensp;скромная комиссия MOTOSHAR.ru &nbsp;за весь комплекс услуг</b></span>
                  </div>
                  <span>&ensp;</span>
                  <span>Все эти пункты отражены в калькуляторе, прозрачно и понятно!</span>
                  <span>&ensp;</span>
               </>,
         
            section4: state ?
               <p>
                  <b>Первый</b> - депозит 10%,&nbsp; полностью зачтётся в мотоцикл<br/>
                  <b>Второй</b> - оплата после покупки мотоцикла на аукционе:<br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- цена мотоцикла в Японии и брокерская комиссия<br/>
                  <b>Третий</b> - по прибытии во Владивосток оплата таможни:<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- таможенная пошлина, СБКТС, СВХ, услуги брокера<br />
                  <b>Четвёртый</b> - мотоцикл&nbsp; готов к отправке,&nbsp; отвозим в ТК:<br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- встречаем &nbsp;его у себя в городе, &nbsp;и оплачиваем ТК<br /><br />
                  Всё известно, проверено и надёжно, ничего лишнего!
               </p>
               :
               <>
                  <span>&ensp;</span>
                  <span><b>Первый</b>: депозит 10%, который полностью зачтётся в стоимость</span>
                  <span>&ensp;</span>
                  <span><b>Второй</b>: после покупки мотоцикла на аукционе нужно оплатить</span>
                  <span>&nbsp;&nbsp; - стоимость мотоцикла в Японии, расходы брокера в Японии</span>
                  <span>&ensp;</span>
                  <span><b>Третий</b>: после оплаты &nbsp;получаем полную фотоопись мотоцикла</span>
                  <span>&ensp;</span>
                  <span><b>Четвёртый</b>: мотоцикл приплывает&nbsp; во Владивосток на таможню</span>
                  <span>&nbsp;&nbsp;- оплачиваем стоимость морской перевозки (фрахт контракт)</span>
                  <span>&nbsp;&nbsp;- оплачиваем пошлину,&nbsp; ЭПТС, &nbsp;СБКТ,&nbsp; СВХ &nbsp;и&nbsp; услуги брокера</span>
                  <span>&ensp;</span>
                  <span><b>Пятый</b>: мотоцикл готов к отправке, подключается транспортная</span>
                  <span>&nbsp;&nbsp;- встречаем &nbsp;его у себя в городе, и оплачиваем &nbsp;стоимость ТК</span>
                  <span>&ensp;</span>
                  <span>Всё известно, многократно проверено и надёжно, ничего лишнего!</span>
                  <span>&ensp;</span>
               </>,
         
            section5: state ?
               <>
                  <h2>Получение мотоцикла в транспортной</h2>
                  <p>
                     Мотоцикл после таможни получает транспортная<br />
                     Надёжно упакуют в обрешётку и закрепят растяжками<br />
                     Возможна дополнительная фанерная защита<br />
                     Останется дождаться поступлении мотоцикла в город,<br />
                     в пункте выдачи осмотреть и оплатить доставку<br /><br />
                  </p>
               </>
               :
               <>
                  <h2>Получение мотоцикла в транспортной компании</h2>
                  <span>&ensp;</span>
                  <span>Мотоцикл после таможенного оформления получает ТК</span>
                  <span>Надёжно закрепят, укроют плёнкой и сделают обрешётку</span>
                  <span>При желании можно сделать наружную фанерную защиту</span>
                  <span>&ensp;</span>
                  <span>Останется дождаться смс о прибытии мотоцикла в город,</span>
                  <span>приехать в ТК, оплатить услуги доставки и получить мотоцикл</span>
               </>,
         
            section6: state ?
               <p>
                  Часто в один день бывает несколько интересных лотов<br />
                  Разные или&nbsp; одной модели, или на&nbsp; разных аукционах<br />
                  Мы можем принять&nbsp; <b>несколько ставок</b> &nbsp;одновременно<br />
                  С условием покупки только одного из всего списка!<br /><br />
                  Ещё много важных моментов:<br />
                  <b>&bull; отправляем СРАЗУ - не ждём сбора контейнера<br />
                  &bull; делаем фотоопись перед отправкой из Японии<br />
                  &bull; поможем с выбором&nbsp; и &nbsp;аукционными листами<br />
                  &bull; всегда на оперативной&nbsp; обратной связи с Вами<br />
                  &bull; есть классный магазин в центре Екатеринбурга<br /></b>
               </p>
               :
               <>
                  <span>&ensp;</span>
                  <span>Часто в один день &nbsp;торгуются несколько&nbsp; интересных Вам мотоциклов</span>
                  <span>Бывают разные или одинаковые модели, даже на разных аукционах!</span>
                  <span>Имеем возможность принять&nbsp; <b>все ставки на эти лоты</b> &nbsp;одновременно</span>
                  <span>С условием покупки только одного из всего списка!</span>
                  <span>&ensp;</span>
                  <span>Ещё несколько не менее важных моментов:</span>
                  <div className={stl?.list}>
                     <span><b>&#10003;&ensp;не ждём сбора контейнера - отправялем мотоциклы сразу!</b></span>
                     <span><b>&#10003;&ensp;детальная фотоопись мотоцикла в Японии&nbsp; после покупки!</b></span>
                     <span><b>&#10003;&ensp;поможем с выбором и разберём любой аукционный лист!</b></span>
                     <span><b>&#10003;&ensp;сопровождаем и&nbsp; всегда&nbsp; на связи от покупки до вручения!</b></span>
                     <span><b>&#10003;&ensp;наш уютный магазин практически в центре Екатеринбурга!</b></span>
                  </div>
               </>,
         
            section7: state ?
               <p>
                  Поможем обслужить Ваш мотоцикл после покупки<br />
                  <br />
                  <b>&bull; накопили существенный опыт работы с мотоциклами<br />
                  &bull; внимательно проверим все узлы и детали мотоцикла<br />
                  &bull; составим детальный чек-лист и согласуем все работы<br />
                  &bull; предложим нужные расходники и техжидкости для ТО<br />
                  &bull; подскажем хорошее&nbsp; дополнительное оборудование<br />
                  &bull; работаем со всей&nbsp; техникой от мопеда до снегоходов<br /></b>
               </p>
               :
               <>
                  <span>&ensp;</span>
                  <span>Поможем обслужить Ваш мотоцикл после покупки и получения</span>
                  <span>Накопили существенный опыт работы с разными мотоциклами</span>
                  <span>&ensp;</span>
                  <div className={stl?.list}>
                     <span><b>&bull;&ensp;тщательно проверим все узлы и детали&nbsp; вашего мотоцикла</b></span>
                     <span><b>&bull;&ensp;составим подробный чек-лист и согласуем нужные работы</b></span>
                     <span><b>&bull;&ensp;подберём все оптимальные запчасти и техжидкости для ТО</b></span>
                     <span><b>&bull;&ensp;поможем выбрать и установить&nbsp; нужное допоборудование</b></span>
                     <span><b>&bull;&ensp;работаем со всей мототехникой, от мопедов до снегоходов</b></span>
                  </div>
               </>,
         
            section8: state ?
               <p>
                  Когда решите продать мотоцикл - привозите его к нам<br />
                  Опыт показывает - мото в магазине продаются быстро<br />
                  Если понадобится&nbsp; - &nbsp;поможем&nbsp;подготовить к продаже<br />
                  <br />
                  <b>&bull; наш магазин практически в центре Екатеринбурга<br />
                  &bull; у нас красиво, тепло, светло, удобно&nbsp; и&nbsp;безопасно<br />
                  &bull; в наличии &nbsp;техжидкости,&nbsp; расходники, экипировка<br />
                  &bull; и между&nbsp;прочим, со всеми +++, совсем не дорого<br /></b>
               </p>
               :
               <>
                  <span>&ensp;</span>
                  <span>Когда наступит &nbsp;время продавать мотоцикл&nbsp; - привозите его к нам</span>
                  <span>Опыт показывает, что техника из магазина&nbsp; заслуживает&nbsp; доверия</span>
                  <span>Если понадобится мотоцикл осмотрит механик, согласуете работы</span>
                  <span>Устраним &nbsp;замечания и мотоцикл станет &nbsp;значительно &nbsp;ликвиднее</span>
                  <span>&ensp;</span>
                  <span>Так же есть возможность принимать&nbsp; технику на зимнее хранение</span>
                  <span>Согласуете с мастером &nbsp;плановые работы&nbsp; для &nbsp;предсезонного ТО</span>
                  <span>И перед новым мотосезоном мотоцикл полностью готов к выезду!</span>
                  <span>&ensp;</span>
                  <div className={stl?.list}>
                     <span><b>&bull;&ensp;наш магазин практически в центре Екатеринбурга</b></span>
                     <span><b>&bull;&ensp;у нас красиво, &nbsp;тепло, светло, удобно, и безопасно</b></span>
                     <span><b>&bull;&ensp;в наличии&nbsp; техжидкости,&nbsp; расходники, экипировка</b></span>
                     <span><b>&bull;&ensp;и между прочим, со всеми +++, совсем не дорого!</b></span>
                  </div>
               </>,
         
            section9: state ?
               <p>
                  <b>{ms.mainData.address}<br />
                  {ms.mainData.cellphone}&nbsp;
                  <a href={`https://api.whatsapp.com/send?phone=${
                        ms.mainData.cellphone.replace(/\D/g, '')}`}
                     target="_blank" rel="nofollow noopener noreferrer"
                  >
                     &ensp;+ whatsapp
                  </a><br />
                  {ms.mainData.email}<br />
                  {/* https://motoshar.ru<br /> */}
                  {/* instagram.com/motoshar.ru<br /> */}
                  vk.com/motoshar</b>
               </p>
               :
               <>
                  <span>&ensp;</span>
                  <span>адрес:&emsp;<b>{ms.mainData.address}</b></span>
                  <span>телефон:&emsp;<b>{ms.mainData.cellphone}</b>&nbsp;
                     <a href={`https://api.whatsapp.com/send?phone=${
                           ms.mainData.cellphone.replace(/\D/g, '')}`}
                        target="_blank" rel="nofollow noopener noreferrer"
                     >
                        &ensp;+ whatsapp&nbsp;
                     </a>
                  </span>
                  <span>email:&emsp;<b><a href={`mailto:${ms.mainData.email}`}>
                     {ms.mainData.email}</a></b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {/* <span>instagram: <b><a href='https://instagram.com/motoshar.ru'>https://instagram.com/motoshar.ru</a></b></span> */}
                  <span>&nbsp;&nbsp;&nbsp;vk:&nbsp;&nbsp;&nbsp;&nbsp;<b>
                     <a rel="nofollow noopener noreferrer"
                        target="_blank"
                        href='https://vk.com/motoshar'
                     >
                        https://vk.com/motoshar
                     </a></b>&nbsp;&nbsp;&nbsp;
                  </span>
               </>,
         }

         return home[props[req].caption]

      case 'lotPlate':
         const lotPlate = {
            lotNum: state ?
               <p>лот - {props.lotPlate.elem.lot_num}</p> :
               <p>№<b>{props.lotPlate.elem.lot_num}</b>, {props.lotPlate.elem.auction}</p>,
         }

         return lotPlate[props[req].caption]

      case 'callback':
         const callback = {
            ownData: state ?
               <span>согласен на обработку личных данных</span> :
               <span>согласен на обработку персональных данных</span>
         }

         return callback[props[req].caption]

      case 'infoBlock':
         const {ratings} = props[req]
         const splitter = ms.splitter

         const infoBlock = {
            rating: state ?
               ratings.count > 1 ?
                  <>
                     <p>минимум<br />
                        {splitter(ratings.finishMin)} руб
                     </p>
                     <p style={{
                           color: 'black',
                           fontWeight: 'bold',
                        }}
                     >
                        средняя<br />
                           {
                              splitter(Math.floor(
                                 ratings.finishSum /
                                 ratings.count / 1000) * 1000)
                           } руб
                     </p>
                     <p>максимум<br />
                        {splitter(ratings.finishMax)} руб
                     </p>
                  </>
               :
                  <>
                     <p></p>
                     <p>по цене<br />
                        {splitter(ratings.finishMin)} руб</p>
                     <p></p>
                  </>
            :
               ratings.count > 1 ?
                  <>
                     <p>мин - {
                        splitter(ratings.finishMin)} руб
                     </p>
                     <p style={{
                           color: 'black',
                           fontWeight: 'bold',
                        }}
                     >
                        средняя - {
                           splitter(Math.floor(
                              ratings.finishSum /
                              ratings.count / 1000) * 1000)
                        } руб
                     </p>
                     <p>макс - {
                        splitter(ratings.finishMax)} руб
                     </p>
                  </>
               :
                  <>
                     <p></p>
                     <p>по цене - {splitter(ratings.finishMin)} руб</p>
                     <p></p>
                  </>
         }
      
         return infoBlock[props[req].caption]

      case 'calc':
         const calc = {
            heading: state ?
               <>
                  <h1>Калькулятор полной стоимости</h1>
                  <h2>учёт всех возможных расходов под "ключ"</h2>
               </>
               :
               <>
                  <h1>Калькулятор стоимости мотоцикла с аукционов Японии</h1>
                  <h2>расчёт с учётом всех возможных расходов под "ключ" в городе заказчика</h2>
               </>,
      
            bigPower: state ?
               <span>от 150 сил?</span> : <span>мощнее 150 сил?</span>,
      
            bidPower: state ? '+ ' : 'акциз ',
      
            kyushuCaption: state ?
               'цена фрахта' : 'фрахт из этого порта дороже',            
         }

         return calc[props[req].caption]

      case 'button':
         const button = {
            'harley-davidson': state ? 'HD' : 'harley-davidson'
         }

         return button[props[req].caption]

      case 'filterBlock':
         const filterBlock = {visible: <></>}

         return filterBlock[props[req].caption]

      case 'lotCard':
         const {elem} = props.lotCard

         const lotCard = {
            statusAndPvi: state ?
               <></>
            :
               <>
                  <p style={{fontWeight: 'bold'}}>
                  {
                     elem.status_lot === 'Ranked' ?
                        <span style={{color: 'green'}}>
                           исправен
                        </span>
                     : 
                        <span style={{color: 'red'}}>
                           повреждён
                        </span>
                  }
                  </p>
                  {
                     elem.pvi_date ?
                        <p>ТО до {elem.pvi_date}</p>
                     : 
                        <></>
                  }
               </>,

            markaAndFrame: state ?
               <>
                  <p>
                     {elem.marka_name.toUpperCase()}&ensp;
                     {elem.model_name.toUpperCase()}
                  </p>
                  <p>рама {elem.serial.toUpperCase()}
                     {
                        elem.engine_model ?
                           <><br/>двигатель {
                              elem.engine_model.toUpperCase()}</>
                        :
                           <></>                        
                     }
                  </p>
               </>
            :
               <>
                  <p>{elem.marka_name.toUpperCase()}</p>
                  <p>{elem.model_name.toUpperCase()}</p>
                  <p>рама {elem.serial.toUpperCase()}</p>
                  {
                     elem.engine_model ?
                        <p>двигатель {
                           elem.engine_model.toUpperCase()}</p>
                     :
                        <></>                        
                  }
               </>,

            yearAndEng: state ?
               <>
                  <p>год<br />{elem.year}</p>
                  <p>объём<br />{elem.eng_v} cm3</p>
                  <p>пробег<br />{ms.checkMileage(elem.mileage)}</p>
                  {
                     elem.mileage_pvi ?
                        <p>пробег на ТО<br />
                           {ms.checkMileage(elem.mileage_pvi)}
                        </p> : <></>
                  }
               </>
            :
               <>
                  <p>год {elem.year}</p>
                  <p>объём {elem.eng_v} см3</p>
                  <p>пробег {ms.checkMileage(elem.mileage)}</p>
                  {
                     elem.mileage_pvi ?
                        <p>пробег на ТО {
                           ms.checkMileage(elem.mileage_pvi)
                        }</p> : null
                  }
               </>,

            descriptionCost: state ?
               <>
                  <span>Цена в <b>РУБ</b> - с учётом <b>всех</b> расходов, документов и услуг</span>
                  <br /><span>"под ключ" во Владивостоке</span>
                  <p>Цена <b>йен</b> - чистая цена в Японии</p>
               </>
            :
               <>
                  <span>Цена в <b>рублях</b> указана с учётом всех расходов, 
                     документов и услуг, </span>
                  <span>плюсом только доставка из Владивостока</span>
                  <p>Цена в <b>йенах</b> - чистая цена на аукционе в Японии</p>
               </>,

            descriptionRating: state ?
               <>
                  <span className='lcdRatingPcs'>
                     рама <span>{elem.rate_frame}</span></span>
                  <span className='lcdRatingPcs'>
                     двиг. <span>{elem.rate_eng}</span></span>
                  <span className='lcdRatingPcs'>
                     эл. <span>{elem.rate_el}</span></span>
                  <span className='lcdRatingPcs'>
                     перед <span>{elem.rate_front}</span></span>
                  <span className='lcdRatingPcs'>
                     зад <span>{elem.rate_rear}</span></span>
                  <span className='lcdRatingPcs'>
                     косм. <span>{elem.rate_ext}</span></span>
               </>
            :
               <>
                  <span className='lcdRatingPcs'>
                     рама&ensp;<span>{elem.rate_frame}</span></span>
                  <span className='lcdRatingPcs'>
                     двигатель&ensp;<span>{elem.rate_eng}</span></span>
                  <span className='lcdRatingPcs'>
                     электрика&ensp;<span>{elem.rate_el}</span></span>
                  <span className='lcdRatingPcs'>
                     перед&ensp;<span>{elem.rate_front}</span></span>
                  <span className='lcdRatingPcs'>
                     зад&ensp;<span>{elem.rate_rear}</span></span>
                  <span className='lcdRatingPcs'>
                     косметика&ensp;<span>{elem.rate_ext}</span></span>
               </>,

            inspection: state ?
               <p data-norate>мотоцикл в процессе осмотра</p>
            :
               <>
                  <p>ожидается</p>
                  <p>осмотр</p>
               </>,                           
         }

         return lotCard[props[req].caption]

      default: return <></>
   }
}

export default CheckMobile