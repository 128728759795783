import {mainState as ms} from '../../initData'
import {createResultList} from './createResultList'
import {useEffect} from 'react'
import stl from './style.module.css'

function ResultBlock() {   
   const {location: loc, navigate: nav} = ms

   const handleClick = e => {
      const id = e.target.parentElement?.id || e.target.offsetParent?.id

      if (e.target.className.includes('plugOnClick') ) {
         ms.saveCurrentFullUrl()
         nav(`/lot${id}`)
      } else if (e.target.tagName === 'IMG') {
         let url = e.target.src
         .replace('image1_small', 'image_cube')
         .replace('.jpg', '_r.jpg')
                  
         ms.saveCurrentFullUrl()
         if (loc.state?.fast) {
            nav(loc.pathname + loc.search, {
               replace: true,
               state: {data: url, fast: 'img', id}
            })
         } else {
            nav(loc.pathname + loc.search, {
               state: {data: url, fast: 'img', id}
            })
         }
      } else return
   }

   const pageUp = () => {
      const ar = document.querySelector('.arrowUp')

      if (window.pageYOffset > 500) {
         ar.classList.add('arrowUpActive')
      } else {
         ar?.classList.remove('arrowUpActive')
      }
   }

   useEffect(() => {
      window.addEventListener('scroll', pageUp)

      return () => {
         window.removeEventListener('scroll', pageUp)
      }
   })

   return (
      <div
         className={stl.resultPlate}
         onClick={handleClick}
      >
         {createResultList()}
         <div
            className='arrowUp'
            onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
         >
            <span></span>
            <span></span>
         </div>
      </div>
   )
}

export default ResultBlock