import Button from '../../../buttons/Button'
import {mainState as ms} from '../../../initData'
import stl from './style.module.css'

const markasPattern = [
   'aprilia',
   'bmw',
   'buell',
   'ducati',
   'harley-davidson',
   'honda',
   'husqvarna',
   'kawasaki',
   'ktm',
   'прочие',
   'moto guzzi',
   'mv agusta',
   'suzuki',
   'triumph',
   'yamaha',
]

function MarkaBlock() {
   const noBackspace = str => str ?
      str.replace(' ', '').replace('прочие', 'other')
         .replace('HD', 'harley-davidson') : ''

   const {navigate: nav, url} = ms
   const startUrl = '/' + url.front
   const urlEngine = url.engine
   const urlMarka = noBackspace(url.marka)

   const onClick = e => {
      if (e.target.className.includes('brands') ) return

      const targetMarka = noBackspace(e.target.textContent)

      if (urlMarka === targetMarka) {
         if (!urlEngine) {
            ms.previousPageUrl = startUrl
            nav(startUrl)
         } else {
            ms.previousPageUrl = `${startUrl}/${urlEngine}`
            nav(`${startUrl}/${urlEngine}`)
         }
      } else {
         if (!urlEngine) {
            ms.previousPageUrl = `${startUrl}/${targetMarka}`
            nav(`${startUrl}/${targetMarka}`)        
         } else {
            ms.previousPageUrl = `${startUrl}/${targetMarka}/${urlEngine}`
            nav(`${startUrl}/${targetMarka}/${urlEngine}`)
         }
      }
   }

   const markaButtons = markasPattern.map(elem => (
      <Button
         class={noBackspace(elem) === urlMarka ? 'brandActive' : 'brand'}
         key={elem}
         name={elem}
      />
   ))

   return (
      <div className={stl.brands} onClick={onClick}>
         {markaButtons}
      </div>
   )
}

export default MarkaBlock