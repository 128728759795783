import {mainState as ms} from "../../initData"
import {useState} from "react"
import Calc from "./Calc"
import stl from './style.module.css'

const CalcPlug = () => {
   const [state, setState] = useState()
   ms.calcPlug = {state, setState}
   const navigate = ms.navigate

   const handleClickClose = e => {
      if (e.target.className.includes('main') ) {
         setState('off')
            
         setTimeout(() => navigate(-1), 600)
      }
   }

   return (
      <div
         className={state === 'off' ? stl.mainClose : stl.main}
         onClick={handleClickClose}
      >
         <div className={stl.bg}>
            <Calc />
         </div>
      </div>
   )
}

export default CalcPlug