import Button from '../../../buttons/Button'
import {mainState as ms} from '../../../initData'
import stl from './style.module.css'

const engine = [
   'до 250 см3',
   '251 - 400 см3',
   '401 - 750 см3',
   '751 - 1300 см3',
   'от 1301 см3',
]

function EngineBlock() {
   const noBackspace = str => str ?
      str.replace(' ', '').replace(' ', '').replace(' ', '')
      .replace('от', '').replace('до', '').replace('см3', '') : ''

   const {navigate: nav, url} = ms
   const startUrl = '/' + url.front
   const {marka} = url
   const existEngine = noBackspace(url.engine)

   const onClick = e => {
      if (e.target.className.includes('engines') ) return

      const targetEngine = noBackspace(e.target.textContent)

      if (existEngine === targetEngine) {
         if (!marka) {
            ms.previousPageUrl = startUrl
            nav(startUrl)
         } else {
            ms.previousPageUrl = `${startUrl}/${marka}`
            nav(`${startUrl}/${marka}`) 
         }
      } else {
         if (!marka) {
            ms.previousPageUrl = `${startUrl}/${targetEngine}`
            nav(`${startUrl}/${targetEngine}`)
         } else {
            ms.previousPageUrl = `${startUrl}/${marka}/${targetEngine}`
            nav(`${startUrl}/${marka}/${targetEngine}`)
         }
      }
   }

   const engineButtons = engine.map(elem => {
      const elemClean = noBackspace(elem)
      const elemArr = elemClean.split('-')

      const classCurrent = !existEngine ? 'engine' :

         elemClean === existEngine ? 'engineActive' :

         elemArr[0] === '250' && +existEngine < 250 ?
            'engineRight' :

         elemArr[0] === '1301' && +existEngine > 1301 ?
            'engineLeft' : 
            
         +elemArr[0] <= +existEngine &&
         (+elemArr[0] + +elemArr[1]) / 2 >= +existEngine ?
            'engineLeft' :

         +elemArr[1] >= +existEngine &&
         (+elemArr[0] + +elemArr[1]) / 2 <= +existEngine ?
            'engineRight' : 'engine'

      return <Button
               class={classCurrent}
               key={elem}
               name={elem}
            />
   })

   return (
      <div className={stl.engines} onClick={onClick}>
         {engineButtons}
      </div>
   )
}

export default EngineBlock