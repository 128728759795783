import {mainState as ms} from '../../initData'
import CheckMobile from '../../CheckMobile'
import './style.css'

function InfoBlock() {
   const cache = ms.cache.lotsFilter

   if (!ms.cache.lotsFilter.length) return <></>

   const {splitter} = ms

   const statInfo = () => {
      if (ms.url.find || ms.url.front !== 'stat') return
      if (ms.cache.filter.recycle) return
      if (!cache) return

      const today = new Date()      

      const tradingFreqMonth = cache.reduce((sum, e) =>
            +e.id.slice(0, 4) === today.getFullYear() ? sum + 1 : sum
         , 0) / ( today.getMonth() + 1 )

      const statYear = {}

      cache.forEach(e => {
         const {finish} = ms.mainData.calcJpyToRub(e)

         if (statYear[e.year]) {
            const y = statYear[e.year]

            if (y[e.rate]) {
               const r = y[e.rate]

               r.finishMin = r.finishMin > finish ? finish : r.finishMin
               r.finishMax = r.finishMax < finish ? finish : r.finishMax
               r.finishSum += finish
               r.count = ++r.count
            } else {
               y[e.rate] = {
                  finishMin: finish,
                  finishMax: finish,
                  finishSum: finish,
                  count: 1,
               }
            }

            statYear[e.year].countAll = ++statYear[e.year].countAll
         } else {
            statYear[e.year] = {
               [e.rate]: {
                  finishMin: finish,
                  finishMax: finish,
                  finishSum: finish,
                  count: 1,
               },
               countAll: 1,
            }
         }
      })

      const statYearReact = []

      const parseRating = obj => {
         const parse = Object.entries(obj)
         parse.pop()

         const str = obj => {
            if (!parse.length) return <></>

            return (
               <>
                  <div className='siddCoast'>
                  {parse[0][0] > 0 ?
                     <>
                        <p>оценка {
                              parse[0][0]}&ensp;-&ensp;{parse[0][1].count
                           } шт
                        </p>
                        <CheckMobile infoBlock={{
                           caption: 'rating',
                           ratings: parse[0][1]
                        }} />
                     </> : <></>
                  }                           
                  </div>
                  {(parse.shift(), str(obj))}
               </>
            )
         }

         return parse.length < 1 ?
            <div className='siddCoast'>
               <p>оценка {parse[0][0]}&ensp;-&ensp;1 шт</p>
               <p></p>
               <p>цена - {splitter(parse[0][1].finishMin)} руб</p>
               <p></p>
            </div> : str(obj)
      }

      for (const i in statYear) {
         if (i !== '0') {
            statYearReact.push(
               <div
                  className='statInfoDifferent'
                  key={i}
               >
                  <p>
                     {i} год выпуска - продано {statYear[i].countAll} шт
                  </p>
                  <div className='sidDetail'>
                     {parseRating(statYear[i])}
                  </div>
               </div>
            )
         }
      }

      return (
         <div id='statInfo'>
            <p id='statInfoDescription'>
               <b>
                  {cache[0].marka_name.toUpperCase()}&nbsp;
                  {cache[0].model_name.toUpperCase()}
               </b>&emsp;
               {
                  tradingFreqMonth / 4 > 1 ?
                     <span>
                        появляется на торгах в среднем {
                           Math.ceil(tradingFreqMonth / 4)
                        } шт в неделю
                     </span> :
                  tradingFreqMonth > 1 ?
                     <span>
                        появляется на торгах в среднем {
                           Math.ceil(tradingFreqMonth)
                        } шт в месяц
                     </span> :
                  <span>на торгах редко, иногда ни одного мотоцикла в месяц</span>
               }
            </p>
            <p>цена в рублях <b>"под ключ"</b> во Владивостоке,&ensp;
               <b>с учётом всех расходов</b></p>
            {statYearReact}
         </div>
      )
   }

   return (
      <div id='infoBlock'>
         {statInfo()}
         <div id='mileageInfo'>
            {ms.mobile() ? <div></div> : <></>}
            <span>
               <span style={{color: 'green', fontWeight: 'bold'}}>km, mi</span>
               <span> - подтверждённый пробег</span>
            </span>
            <span>
               <span style={{fontWeight: 'bold'}}>
                  цена в рублях - это цена с учётом всех расходов во Владивостоке!
               </span>
            </span>
         </div>
      </div>
   )
}

export default InfoBlock