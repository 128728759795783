import {mainState as ms} from "../../../initData"

// получает список моделей из cache, после sortByFilterBlockState
// возвращает {}, state для ModelBlock
// сортирует список по выбранным моделям из URL
export function createModelList() {
   const {models} = ms.url
   let modelsJsx = ms.url.front === 'online' || ms.url.find ?
      ms.cache.modelsFilter : ms.cache.modelsFetch

   if (modelsJsx) {
      modelsJsx = modelsJsx.sort((a, b) => a < b ? -1 : 1)
      .sort(e => models.includes(e) ? -1 : 1)
      .map((e, i) => {
         return (
            <div
               className={models.includes(e) ?
                  'modelBlockItem buttonActive modelActive' : 'modelBlockItem'}
               key={i}
               style={models.includes(e) ? 
                  ms.mobile() ?
                     {top: i * 16 + 1 + '%'}
                     :
                     {top: i * 13 + 1 + '%'}
                  :
                  {}
               }
            >
               {e}
            </div>
         )
      })
   } else modelsJsx = ''

   return modelsJsx
}
