import {mainState as ms} from "../initData"

export function cleaner() {
   if (ms.url.find && ms.url.find !== ms.cache.lastFind) {
      cleanCache()      
   } else if (ms.url.find && ms.url.find === ms.cache.lastFind) {
      return
   }
   else if (
      !ms.url.marka || !ms.url.engine ||
      ms.url.marka !== ms.cache.lastMarka ||
      ms.url.engine !== ms.cache.lastEngine
   ) {
      cleanCache()
   }

   if (ms.url.front === 'online') {
      
   }

   if (ms.url.front === 'stat') {
      // смена модели
      if (
         ms.url.models[0] && ms.cache.lastModel &&
         ms.url.models[0] !== ms.cache.lastModel
      ) {
         cleanCache('statusLots modelsFetch')
      }

      // снятие модели
      if (
         ms.url.marka && ms.url.engine && !ms.url.models[0]
      ) {
         cleanCache('statusLots modelsFetch lastMarka lastEngine')
      }
   }

   function cleanCache(except) {
      for (let val in ms.cache) {
         except ?
            !except.includes(val) && (ms.cache[val] = '')
         :
            ms.cache[val] = ''
      }
   }
}