import {mainState as ms} from '../../initData'
import './style.css'

function FilterBlock() {
   const {location: loc, navigate: nav} = ms
   const {statusLots} = ms.cache
   let state = ms.cache.filter || ms.filter

   const handleChange = val => {
      ms.saveCurrentFullUrl()

      if (loc.search) {
         let filter = loc.search.replace('?', '').split('&')

         // удаляет совпадающие свойства вместе со старыми значениями
         filter[filter.length - 1].includes(statusLots) &&
            (filter.length = filter.length - 1)
         filter.forEach((e, i, a) => e.includes(val[0]) && a.splice(i, 1) )
         
         // проверка на крайние значения в списках
         state.controls[val[0]] !== +val[1] && filter.push(val.join('=') )

         filter = filter.join('&')

         if (filter) {
            filter = '?' + filter +
               (statusLots === 'Recycle' ? '&status=Recycle' : '')
         } else {
            filter = statusLots === 'Recycle' ? '?status=Recycle' : ''
         }

         nav(loc.pathname + filter)
      } else {
         nav(loc.pathname + '?' + val.join('=') +
            (statusLots === 'Recycle' ? '&status=Recycle' : '') )
      }
   }

   function handleResetFilter() {
      let url

      if (ms.url.front === 'online' || ms.url.find) {
         url = ms.url.models[0]
            ? loc.pathname.slice(0, loc.pathname.lastIndexOf('/') )
            : loc.pathname
      } else if (ms.url.front === 'stat') {
         url = loc.pathname
      }

      url += statusLots === 'Recycle' ? '?status=Recycle' : ''

      ms.previousPageUrl = url
      nav(url)
   }

   function checkFilter() {
      if (ms.url.models[0] && (ms.url.front === 'online' || ms.url.find) )
         return true

      for (let prop in ms.url.filter) {
         if (prop !== 'status') return true
      }
      
      return false
   }

   return (
      <div className='filterBlockFilter'>
         <div>
            <span>оценка</span>
            <select
               disabled={state.recycle ? true : state.disabled}
               name='rateMin'
               onChange={e => handleChange(['rateMin', e.target.value])}
               value={state.rateMin}
               >{state.ratingsMin}</select>
            &ndash;
            <select
               disabled={state.recycle ? true : state.disabled}
               name='rateMax'
               onChange={e => handleChange(['rateMax', e.target.value])}
               value={state.rateMax}
               >{state.ratingsMax}</select>
         </div>
         <div>
            <span>год</span>
            <select
               disabled={state.disabled}
               name='yearMin'
               onChange={e => handleChange(['yearMin', e.target.value])}
               value={state.yearMin}
               >{state.yearsMin}</select>
            &ndash;
            <select
               disabled={state.disabled}
               name='yearMax'
               onChange={e => handleChange(['yearMax', e.target.value])}
               value={state.yearMax}
               >{state.yearsMax}</select>
         </div>
         <div>
            <span>пробег</span>
            <select
               disabled={state.disabled}
               name='mileageMin'
               onChange={e => handleChange(['mileageMin', e.target.value])}
               value={state.mileageMin}
               >{state.mileagesMin}</select>
            &ndash;
            <select
               disabled={state.disabled}
               name='mileageMax'
               onChange={e => handleChange(['mileageMax', e.target.value])}
               value={state.mileageMax}
               >{state.mileagesMax}</select>
         </div>
         <div>
            <input
               checked={state.ranked}
               className='rankedFilterBlock'
               disabled={state.disabled}
               name='statusRanked'
               onChange={() => {
                  ms.saveCurrentFullUrl()
                  nav(loc.pathname)
               }}
               type='radio'
               />
            <span>исправен</span>
            <input
               checked={state.recycle}
               className='recycleFilterBlock'
               disabled={state.disabled}
               name='statusRecycle'
               onChange={() => {
                  ms.saveCurrentFullUrl()
                  nav(loc.pathname + '?status=Recycle')
               }}
               type='radio'
               />
            <span>повреждён</span>
         </div>
         <span
            className={checkFilter() ? 'reset resetActive' : 'reset'} 
            name='filterReset'
            onClick={e => {
               if (!e.target.className.includes('resetActive') ) return
               if (ms.url.find || (ms.url.marka && ms.url.engine) ) {
                  handleResetFilter()
               }
            }}
         >сбросить фильтр</span>
      </div>
   )
}

export default FilterBlock
