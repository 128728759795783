import {mainState as ms} from '../../../initData'
import {createModelList} from './createModelList'
import './style.css'

function ModelBlock() {
   const {location: loc, navigate: nav} = ms

   const handleClick = e => {
      if (e.target.id === 'modelBlock' || e.target.id === 'notFound') return
      
      const {textContent: targetModel} = e.target
      let urlModels = [...ms.url.models]

      // удаляем или добавляем модель в массив моделей
      if (urlModels.includes(targetModel) ) {
         urlModels.splice(urlModels.indexOf(targetModel), 1)
      } else if (ms.url.front === 'online' || ms.url.find) {
         urlModels.push(targetModel)
      } else {
         urlModels.length = 0
         urlModels.push(targetModel)
      }

      let newPathname = loc.pathname

      if (ms.url.models[0]) {
         newPathname = loc.pathname.slice(0, loc.pathname.lastIndexOf('/') )
      }

      urlModels = urlModels[0] ? '/' + [...urlModels].join('<>') : ''

      // для статистики смена модели сбрасывает настройки фильтра
      ms.url.front === 'stat' && !ms.url.find ?
         newPathname = newPathname + urlModels
      :
         newPathname = newPathname + urlModels + loc.search

      ms.previousPageUrl = newPathname
      nav(newPathname)
   }

   return (
      <div
         // id={state ? 'modelBlock' : 'modelBlockEmpty'}
         id='modelBlock'
         onClick={handleClick}
      >
         {createModelList()}
      </div>
   )
}

export default ModelBlock
