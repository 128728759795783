import {mainState as ms} from '../../initData'
import {cleaner} from '../cleaner'
import './style.css'

function FindLotNum() {
   const {location: loc, navigate: nav} = ms
   const startUrl = '/' + loc.pathname.split('/')[1]
   
   function handleSubmit(e) {
      if (+e.target[0].value) {
         cleaner()
         
         ms.lastFindLotNum = +e.target[0].value
         
         nav(`${startUrl}/find${e.target[0].value}`)
      }

      e.target.reset()
      e.preventDefault()
   }

   return (
      <div id='findLotNum'>
         <p>поиск</p>
         <form onSubmit={handleSubmit}>
            <input
               max='9999'
               maxLength='4'
               name='findLot'
               onKeyPress={e => e.key === 'Enter' ? handleSubmit : null}
               type='tel'
               placeholder='№ лота'
            /> 
            <input type='submit' value=' '/>
         </form>    
      </div>
   )
}

export default FindLotNum