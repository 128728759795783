import {mainState as ms} from "../../initData"
import lotCardFull from "./lotCard/lotCardFull"
import lotCardMain from "./lotCard/lotCardMain"
import lotCardEmpty from "./lotCard/lotCardEmpty"
import NavFilter from "../../nav/navFilter/NavFilter"
import {fetchLotCard} from "../fetchs/fetchLotCard"
import CloseButton from "../../closeButton/CloseButton"
import {useParams} from "react-router-dom"
import {useEffect} from "react"

function LotPage() {
   const {location: loc, navigate: nav} = ms
   const params = useParams()

   let {id} = params
   const {lastLot} = ms.cache

   if (lastLot !== id) {
      fetchLotCard(id)
      ms.lotCard.srcImgsWork = {}
      ms.lotCard.srcVideoWork = {}
      ms.cache.lotFetch = ''
   }

   const lot = ms.lotCard.lot = ms.cache.lotFetch[0] ||
      (ms.cache.lotsFetch && ms.cache.lotsFetch.find(e => e.id === id) ) || ''

   const date = lot ? lot.auction_date.slice(0, 10) : (
      id = id.slice(0, 8).split(''), id.splice(4, 0, '-'),
      id.splice(-2, 0, '-'), id = id.join(''), id)

   function choiceLotCard(date) {
      const cards = {
         full: lotCardFull,
         main: lotCardMain,
         empty: lotCardEmpty,
      }

      return cards[date]
   }

   const handleClickClose = () => {
      if (ms.mobile() ) {
         window.open(
            `https://api.whatsapp.com/send?phone=${
            ms.mainData.cellphone.replace(/\D/g, '')}`
         )
      } else {
         if (ms.previousPageUrl) {
            nav(-1)
         } else {
            const lot = ms.lotCard.lot
            let date = lot.auction_date.slice(0, 10)
            date = (Date.parse(date) + 86400000) - Date.now()

            const checkEngine = num =>
               num <= 250 ? '250' :
               num >= 251 && num <= 400 ? '251-400' :
               num >= 401 && num <= 750 ? '401-750' :
               num >= 751 && num <= 1300 ? '751-1300' : '1301'

            if (date >= 0) {
               nav('/online/' + lot.marka_name + '/' + checkEngine(lot.eng_v) )
            } else {
               nav(
                  '/stat/' + lot.marka_name + '/' + checkEngine(lot.eng_v)
                  + '/' + lot.model_name
               )                 
            }
         }
         ms.saveCurrentFullUrl()
      }
   }

   useEffect(() => {
      ms.header.setMenuMob(loc.state?.navMob || loc.state?.upClose)
      ms.nav.setNavMob(loc.state?.navMob)
   })

   const emptyPattern =
      <div id='lotCard'>
         <div id='h1AndDateUpdate'>
            <h1>Аукционный лист</h1>
            <div id='dateUpdate'>
               <span>обновлён </span>
               <span>{ms.localDate({})}</span>
            </div>
         </div>
         <div id='lotCardMainInfo'>
            <div id='lcmiImg' className='itemDiv itemDivImg' />
            <div className='itemDiv itemDivLot' />
            <div className='itemDiv itemDivMarka' />
            <div className='itemDiv itemDivYear' />
            <div className='itemDiv itemDivCost' />
            <div className='itemDiv itemDivRating' />
         </div>
      </div>

   return (
      <>
         <CloseButton className='lot' onClick={handleClickClose} />
         {lot ? choiceLotCard(ms.imgValidCheck(date) )()
         : emptyPattern}
         <NavFilter />
      </>
   )
}

export default LotPage