import {mainState as ms} from '../../initData'
import {useState} from 'react'
import stl from '../style.module.css'

function NavHome() {
   const {navigate} = ms
   const [state, setState] = useState(0)
   const [navMob, setNavMob] = useState(false)
   ms.nav = {state, navMob, setState, setNavMob}
   const sections = document.querySelectorAll('section')
   
   const handleClick = e => {      
      const i = +e.nativeEvent.target.dataset.key ||
      +e.target.offsetParent.dataset.key
      
      const coord = i === 0 ? 0 : sections[i].offsetTop
      
      window.scrollTo({top: coord, behavior: 'smooth'})

      ms.saveCurrentFullUrl()
      navigate('/')
   }

   const navActive = []
   
   const navDescription = [
      'торги',
      'статистика',
      'калькулятор',
      'оплата',
      'преимущества',
      'мотосервис',
      'комиссия',
      'контакты',
   ]

   for (let i = 0; i < navDescription.length; i++) {
      navActive.push(
         <div
            className={i === state ? stl.iconActive : stl.icon}
            data-key={i}
            onClick={handleClick}
            key={i}
         >
            <img
               alt="icon"
               src={require(`../img/${navDescription[i]}.webp`)}
            />
            <div>{navDescription[i]}</div>
         </div>
      )
   }

   return (
      <nav className={navMob ? stl.navMob : stl.nav}>
         {navActive}
      </nav>
   )
}

export default NavHome