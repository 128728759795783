import {mainState as ms} from "../../initData"
import {costBlock} from '../../auction/costBlock'
import {useState} from "react"
import arrowLeft from '../../img/arrowLeft.png'
import arrowRight from '../../img/arrowRight.png'
import stl from '../../style.module.css'

function ImgWrapper() {
   const {location: loc, navigate: nav} = ms
   const urlLot = loc.pathname.includes('lot')
   const [state, setState] = useState()
   ms.imgWrapper = {state, setState}

   const lotImgs = urlLot && ms.lotCard.srcImgsWork
      && Object.keys(ms.lotCard.srcImgsWork).sort()
   // [lotImgs[0], lotImgs[1], lotImgs[3], lotImgs[4]] =
   //    [lotImgs[3], lotImgs[4], lotImgs[1], lotImgs[0]]
   if (lotImgs && lotImgs[4]) {
      let a = lotImgs[0]
      let b = lotImgs[1]
      let l = lotImgs[3]
      let r = lotImgs[4]
      lotImgs[3] = a      
      lotImgs[4] = b      
      lotImgs[1] = l      
      lotImgs[0] = r      
   }
   
   const handleScroll = e => {
      const i = lotImgs.indexOf(loc.state?.data)
  
      if (document.documentElement.scrollWidth / 2 < e.clientX) {
         if (lotImgs.length - 1 !== i) {
            loc.state.data = lotImgs[i + 1]         
            setState({})            
         } else {
            loc.state.data = lotImgs[0]
            setState({})
         }
      } else {
         if (i === 0) {
            loc.state.data = lotImgs[lotImgs.length - 1]
            setState({})
         } else {
            loc.state.data = lotImgs[i - 1]
            setState({})
         }
      }
   }

   const lot = ms.cache.lotFetch[0] ||
      (ms.cache.lotsFetch && ms.cache.lotsFetch.find(
         e => e.id === loc.state?.id) ) || ''

   return (
      <div className={urlLot ? stl.imgWrapperLot : stl.imgWrapperPlate}>
         <div
            className={urlLot ? stl.scrllLeft : stl.scrlNo}
            onClick={handleScroll}
         >
            <img src={arrowLeft} alt='arrowLeft' />
         </div>
         <div style={{position: 'relative'}}>
            {loc.pathname.includes('lot') || !lot ? '' :
               <div className={stl.watermark}>
                  <span style={{textTransform:'uppercase'}}>
                     {lot?.marka_name} {lot?.model_name}&emsp;</span>
                  <span>год {lot?.year}&emsp;</span>
                  <span>пробег {lot?.mileage}&emsp;</span>
                  <span>оценка {lot?.rate}&emsp;</span>
                  <span>
                     торги {ms.localDate({
                        date: lot?.auction_date, ru: 1, longMonth: 1})}&emsp;
                  </span>               
                  {lot ? costBlock(lot) : ''}            
               </div>
            }
            <img
               alt='img'
               className={stl.img}
               onClick={() => nav(-1)}
               onLoad={e => {
                  e.target.style.opacity = 1
                  setTimeout(() => {
                     e.target.previousSibling.style = `
                     box-shadow: 0 2.9vw 0vw 0vw white;
                     opacity: 1;
                     text-shadow: 0 3.7vw black;
                     `
                  }, 400)
               }}   
               onMouseOut={() => !urlLot && nav(-1)}
               src={loc.state?.data}
               style={{width: !ms.mobile() && urlLot ? '64vw' : '100%'}}
            />
         </div>
         <div
            className={urlLot ? stl.scrlRight : stl.scrlNo}
            onClick={handleScroll}
         >
            <img src={arrowRight} alt='arrowRight' />
         </div>
      </div>
   )
}

export default ImgWrapper