import {mainState as ms, noScroll} from '../initData'
import {Preloader} from '../Preloader'
import {cleaner} from '../auction/cleaner'
import {useState} from 'react'
import stl from './style.module.css'

function Header() {
   const {location: loc, navigate: nav} = ms

   const [menuMob, setMenuMob] = useState(false)
   ms.header = {menuMob, setMenuMob}

   noScroll()

   const MenuMobSpan = props => {
      
      return (
         <>
            <span className={props.on ? 
               stl.menuMobOnSpan1 : stl.menuMob} />
            <span className={props.on ?
               stl.menuMobOnSpan2 : stl.menuMob} />
            <span className={props.on ?
               stl.menuMobOnSpan3 : stl.menuMob} />
         </>
      )
   }

   const handleClickLogo = () => {
      setMenuMob()
      ms.nav.setNavMob()
      ms.preloader()
      cleaner()

      window.scrollTo({top: 0, behavior: 'smooth'})

      ms.saveCurrentFullUrl()
      nav('/')
   }
   
   const handleClickMenu = () => {
      if (!ms.mobile() ) return
      
      ms.saveCurrentFullUrl()
      nav(loc.pathname + loc.search, {state: {navMob: !menuMob}})
   }

   return (
      <>
         
         <header className={stl.header}>
            <div
               className={stl.logoDiv}
               onClick={handleClickLogo}
            >  
               <Preloader />
               <div className={stl.text}>
                  <p>МОТОШАР</p>
                  <p>мотоаукционы Японии</p>
               </div>
            </div>
            <div className={stl.logoDivAngle}></div>
            <div className={
               (ms.location.pathname !== '/' && stl.headerAuc) || ''
            }>
               <div
                  className={stl.address}
                  onClick={handleClickMenu}
               >
                  <p className={stl.addressP}>{ms.mainData.address}</p>
                  <MenuMobSpan on={menuMob} />
               </div>
               <div className={stl.phone}>
                  <span>{ms.mainData.cellphone}</span>
                  <a href={`https://api.whatsapp.com/send?phone=${
                     ms.mainData.cellphone.replace(/\D/g, '')}`}
                     target="_blank" rel="nofollow noopener noreferrer"
                  ></a>
               </div>
            </div>
         </header>
      </>
   )
}

export default Header
