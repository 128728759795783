import {mainState as ms} from "../initData"

export function parseUrl() {
   const {pathname, search} = ms.location

   if (!pathname) return ({
      engine: '',
      filter: {},
      marka: '',
      models: [],
   })
   
   const url = decodeURI(pathname).split('/').slice(1)
   const urlToObj = {front: url[0]}

   if (pathname.includes('find') ) {
      urlToObj.find = url[1].slice(4)
      urlToObj.models = url[2]
   } else if (pathname.includes('lot') ) {
      // console.log('lot')
   } else {
      // если выбран только мотор без марки
      if (url[1] && +url[1].replace('-', '') ) {
         urlToObj.marka =  ''
         urlToObj.engine = url[1]
      } else if (url[1]) {
         urlToObj.marka =  url[1]
         urlToObj.engine = url[2] || ''
         urlToObj.models = url[3]
      }
   }

   urlToObj.models ?
      urlToObj.models.includes('<>') ?
         urlToObj.models = urlToObj.models.split('<>') :
         urlToObj.models = urlToObj.models.split()
   :
      urlToObj.models = []

   urlToObj.filter = {}
   
   if (search) {
      search.replace('?', '').split('&').forEach(e => {
         urlToObj.filter[e.split('=')[0]] = e.split('=')[1]
      })
   }

   return urlToObj
}