import imgStat from './img/stat.webp'
import imgOnline from './img/online.webp'
import imgCalc from './img/calc.webp'
import imgPrice from './img/price.webp'
import imgHi from './img/hi.webp'
import imgRepair from './img/repair.webp'
import imgStore from './img/store.webp'
import imgContacts from './img/contacts.webp'
import {homeAnimation} from './homeAnimation'
import Button from '../buttons/Button'
import NavHome from '../nav/navHome/NavHome'
import Footer from '../footer/Footer'
import {mainState as ms} from '../initData'
import stl from './style.module.css'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import CheckMobile from '../CheckMobile'
import {getAucDays} from '../auction/getAucDays'

function Home() {
   const {location: loc, navigate: nav} = ms

   useEffect(() => {
      ms.header.setMenuMob(loc.state?.navMob || loc.state?.upClose)
      ms.nav.setNavMob(loc.state?.navMob)

      function AnimationData() {
         this.totalHeight = document.body.scrollHeight
         this.winHeight = window.innerHeight
         this.angleLines = document.getElementById(stl.angleLines)
         this.header = document.querySelector('header').offsetHeight
         this.sections = document.querySelectorAll('section')
         this.sectionTops = Array.from(this.sections).map(e => e.offsetTop)
         this.imgs = document.querySelectorAll('.' + stl.img)
         this.pos = () => window.scrollY
         this.partScreen = (this.winHeight - this.header) / 7
         this.sectionsStartStop = this.sectionTops.map((e, i) =>
            [e + this.partScreen,
            e + this.sections[i].scrollHeight - this.partScreen])   
         this.middle = () => this.pos() + this.header + this.winHeight / 2
         this.ms = ms
      }

      const animation = homeAnimation.bind(null, new AnimationData() )

      window.addEventListener('scroll', animation)
   
      return () => {
         window.removeEventListener('scroll', animation)
      }
   })

   return (
      <>
         <NavHome />
         <div id={stl.angleLines}></div>
         <section className={`${stl.section} ${stl.section1}`}>
            <div className={stl.divH1H2}>
               <h1 className={stl.h1}>
                  Мотоциклы из Японии с аукционов под заказ
               </h1>
               <h2 className={stl.h2}>
                  <CheckMobile home={{caption: 'h2'}} />
               </h2>
            </div>            
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgOnline} alt='img' />
            </div>
            <div className={stl.description}>
               <h2>На странице торгов всегда много вариантов</h2>
               <h2>Выбирайте мотоциклы на ближайших торгах</h2>
               <CheckMobile home={{caption: 'section1', stl}} />
               <div className={stl.buttons}>
                  <Link
                     className={stl.link}
                     onClick={() => ms.saveCurrentFullUrl()}
                     to='online'
                  >
                     <Button
                        name={`торги - ${getAucDays(true)[0]}`}
                        class='home'
                     />
                  </Link>
               </div>
            </div>            
         </section>
         <div className={stl.middleLine}></div>
         <section className={`${stl.section} ${stl.section2}`}>
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgStat} alt='img' />               
            </div>
            <div className={stl.description}>
               <h2>в этот &nbsp;раздел попадают все проданные лоты</h2>
               <h2>статистика хранит цены для всех мотоциклов</h2>
               <CheckMobile home={{caption: 'section2', stl}} />
               <div className={stl.buttons}>
                  <Link
                     className={stl.link}
                     onClick={() => ms.saveCurrentFullUrl()}
                     to='stat'
                  >
                     <Button
                        name='статистика'
                        class='home'
                     />
                  </Link>
               </div>
            </div>
         </section>
         <div className={stl.middleLine}></div>
         <section className={`${stl.section} ${stl.section3}`}>
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgCalc} alt='img' />               
            </div>
            <div className={stl.description}>
               <CheckMobile home={{caption: 'section3', stl}} />
               <div className={stl.buttons}>
                  <Button
                     class='home'
                     name='калькулятор'
                     onclick={e => {
                        ms.saveCurrentFullUrl()
                        nav('/', {state: {
                           fast: e.target.textContent,
                           upClose: 1
                        }})
                     }}
                  />
               </div>
            </div>
         </section>
         <div className={stl.middleLine}></div>
         <section className={`${stl.section} ${stl.section4}`}>
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgPrice} alt='img' />               
            </div>
            <div className={stl.description}>
               <h2>пошагово указали дорожную карту покупки</h2>
               <h2>весь&nbsp; процесс&nbsp; заказа&nbsp; поэтапно с оплатами</h2>
               <CheckMobile home={{caption: 'section4', stl}} />
            </div>
         </section>
         <div className={stl.middleLine}></div>
         <section className={`${stl.section} ${stl.section5}`}>
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgHi} alt='img' />               
            </div>
            <div className={stl.description}>
               <h2>накопили&nbsp; такой весомый опыт, что смело </h2>
               <h2>предлагаем&nbsp; вам&nbsp; ощутимые &nbsp;преимущества!</h2>
               <CheckMobile home={{caption: 'section6', stl}} />
            </div>
         </section>
         <div className={stl.middleLine}></div>
         <section className={`${stl.section} ${stl.section6}`}>
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgRepair} alt='img' />               
            </div>
            <div className={stl.description}>
               <h2>широкие возможности выбора запчастей</h2>
               <h2>техническое &nbsp;обслуживание &nbsp;мототехники</h2>
               <CheckMobile home={{caption: 'section7', stl}} />
            </div>
         </section>
         <div className={stl.middleLine}></div>
         <section className={`${stl.section} ${stl.section7}`}>
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgStore} alt='img' />               
            </div>
            <div className={stl.description}>
               <h2>комиссионная продажа&nbsp; & &nbsp;зимнее хранение</h2>
               <h2>мотоциклов в нашем магазине или ремзоне</h2>
               <CheckMobile home={{caption: 'section8', stl}} />    
            </div>
         </section>
         <div className={stl.middleLine}></div>
         <section className={stl.sectionLast}>
            <div className={stl.imgWrapper}>
               <img className={stl.img} src={imgContacts} alt='img' />               
            </div>
            <div className={stl.description}>
               <h2>Контакты</h2>
               <CheckMobile home={{caption: 'section9', stl}} />            
            </div>
         </section>
         <Footer />
      </>
   )
}

export default Home
